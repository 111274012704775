import React, { useEffect, useState } from "react";
import "./Reservation.css";

import BookingWidget from "./BookingWidget.js";
import GiftCardWidget from "./GiftCardWidget.js";

import goBackArrow from "../assets/krok-zpet-sipka.svg";
import { Link } from "react-router-dom";

const Reservation = () => {
  const [index, setIndex] = useState(
    () => Number(localStorage.getItem("index")) || 0
  );

  useEffect(() => {
    document.title = "Kosmetika Michalčíková | Rezervace";
  }, []);

  const toggleWidget = () => {
    setIndex((prevIndex) => (prevIndex === 0 ? 1 : 0));
  };

  // blikani teček na gift card widget
  const h1Style = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    margin: 0,
    fontSize: "2em",
    fontWeight: "bold",
    textAlign: "center",
  };

  const dotStyle = {
    animation: "blink 1s infinite step-start",
  };

  // Render
  return (
    <>
      {(() => {
        switch (index) {
          case 0:
            return (
              <section className="res__intro">
                <button className="btn gift__btn-back" onClick={toggleWidget}>
                  Chci darovat/koupit poukaz
                </button>
                <Link to="/" className="btn-back">
                  <img src={goBackArrow} alt="Zpět domů" />
                </Link>
                <BookingWidget />
              </section>
            );
          case 1:
            return (
              <>
                <Link to="/" className="btn-back">
                  <img src={goBackArrow} alt="Zpět domů" />
                </Link>
                <button className="btn gift__btn-back" onClick={toggleWidget}>
                  Chci se rezervovat
                </button>
                <div>
                  {/* <GiftCardWidget /> */}
                  <style>
                    {`
                    @keyframes blink {
                        0%, 100% {
                            opacity: 1;
                        }
                        50% {
                            opacity: 0;
                        }
                    }
                `}
                  </style>
                  <h1 style={h1Style}>
                    Na této funkci se pracuje
                    <span style={{ ...dotStyle, animationDelay: "0s" }}>.</span>
                    <span style={{ ...dotStyle, animationDelay: "0.2s" }}>
                      .
                    </span>
                    <span style={{ ...dotStyle, animationDelay: "0.4s" }}>
                      .
                    </span>
                  </h1>
                </div>
              </>
            );

          default:
            return <p>Něco se pokazilo {index}</p>;
        }
      })()}
    </>
  );
};

export default Reservation;
