import React, { useState, useEffect } from "react";
import "./App.css";
import Home from "./pages/Home.jsx";
import Services from "./pages/Services.jsx";
import Reservation from "./pages/Reservation.jsx";
import Overview from "./pages/Overview.jsx";
import { BrowserRouter, Routes, Route } from "react-router-dom";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/sluzby" element={<Services />}></Route>
        <Route path="/rezervace" element={<Reservation />}></Route>
        {/* <Route path='/prehled-rezervaci' element={<Overview />}></Route> */}
      </Routes>
    </BrowserRouter>
  );
};

export default App;
