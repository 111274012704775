import React from "react";
import "./Footer.css";
import logo from "../assets/logo.webp";
import { FaInstagram } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa";
import { IoMailOutline } from "react-icons/io5";

const Footer = () => {
  return (
    <footer id="kontakt">
      <div className="footer__content">
        <div className="footer__links__wrap">
          <img className="footer__logo" src={logo} alt="logo" />
          <a
            className="footer__socials"
            target="_blank"
            href="https://www.instagram.com/kosmetika.michalcikova?igsh=YjRnejRpY2FmYXc3"
            rel="noopener noreferrer"
          >
            <FaInstagram className="footer__icons" />
          </a>
          <a
            className="footer__socials"
            target="_blank"
            href="https://www.facebook.com/profile.php?id=61560177094080"
            rel="noopener noreferrer"
          >
            <FaFacebookF className="footer__icons" />
          </a>
          <a className="footer__socials" href="mailto:s-michalcikova@seznam.cz">
            <IoMailOutline className="footer__icons" />
          </a>
        </div>
        <div className="footer__text__wrap">
          <a href="tel:+420775981566" type="tel">
            +420 775 981 566
          </a>
          <a href="mailto:s-michalcikova@seznam.cz" type="email">
            s-michalcikova@seznam.cz
          </a>
        </div>
        <p>
          &copy; 2024,{" "}
          <a
            href="https://love2do.cz"
            target="_blank"
            style={{ margin: "0px" }}
            rel="noopener noreferrer"
          >
            Love2Do s.r.o.
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
