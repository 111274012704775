import React, { useState, useEffect } from "react";
import "./Services.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { gsap } from "gsap";
import ScrollSmoother from "gsap/ScrollSmoother";
import Navbar from "../components/Navbar";
import { ImgComparisonSlider } from "@img-comparison-slider/react";
import servicesBackground from "../assets/sluzby-background.jpg";
import leaf from "../assets/listecek2.svg";
import waterDrop from "../assets/kapka.svg";
import Footer from "../components/Footer";
import { servicesData } from "./data.js"; // Import statických dat

gsap.registerPlugin(ScrollSmoother);

const Services = () => {
  useEffect(() => {
    document.title = "Kosmetika Michalčíková | Služby";
  }, []);

  const [animation, setAnimation] = useState(false);
  const [scrollValue, setScrollValue] = useState(window.scrollY);
  const [scrolled, setScrolled] = useState(false);
  const white = true;

  useEffect(() => {
    ScrollSmoother.create({
      wrapper: "#wrapper",
      content: "#content",
      smooth: 1,
      speed: 1,
      effects: true,
    });
  }, []);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setScrollValue(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (scrollValue > 1) setScrolled(true);
    else setScrolled(false);
  }, [scrollValue]);

  useEffect(() => {
    setTimeout(() => setAnimation(true), 200);
  }, []);

  const navigate = useNavigate();

  const handleSluzbyClick = () => {
    navigate("/rezervace");
  };

  const scrollToContact = () => {
    gsap.to(window, {
      scrollTo: "#kontakt",
      duration: 0.5,
      ease: "power1.inOut",
    });
  };

  return (
    <>
      <Navbar
        animation={animation}
        scrolled={scrolled}
        scrollValue={scrollValue}
        white={white}
      />
      <img
        className="hero__background"
        src={servicesBackground}
        style={{
          filter: `blur(${scrollValue / 100 < 7 && scrollValue / 50}px)`,
        }}
        alt=""
      />
      <div id="wrapper">
        <div id="content">
          <main>
            <div
              className="hero"
              style={
                windowWidth > 864
                  ? {
                      filter: `blur(${
                        scrollValue / 100 < 7 && scrollValue / 80
                      }px)`,
                      top: `${-(scrollValue / 150)}px`,
                    }
                  : {}
              }
            >
              <section>
                <div
                  style={
                    windowWidth > 864
                      ? {
                          top: `${scrollValue ? -(scrollValue / 8) + 35 : 35}%`,
                        }
                      : {}
                  }
                  className="hero__title__wrap"
                >
                  <h1
                    className={`${
                      animation && "hero__title__appear"
                    } hero__title`}
                  >
                    Kosmetika Michalčíková
                  </h1>
                </div>
                <div
                  style={
                    windowWidth > 864
                      ? {
                          top: `${scrollValue ? -(scrollValue / 6) + 35 : 35}%`,
                        }
                      : {}
                  }
                  className="hero__description__wrap"
                >
                  <p
                    className={`${
                      animation && "hero__description__active"
                    } hero__description`}
                  >
                    moje služby
                  </p>
                </div>
                <div
                  className="hero__buttons"
                  style={
                    windowWidth > 864
                      ? {
                          top: `${
                            scrollValue ? -(scrollValue / 3) + 82.5 : 82.5
                          }%`,
                        }
                      : {}
                  }
                >
                  <div
                    className={`${
                      animation && "hero__button__wrap__appear1"
                    } hero__button__wrap`}
                  >
                    <Link
                      to="/sluzby"
                      className="hero__button btn"
                      onClick={scrollToContact}
                    >
                      kontakt
                    </Link>
                  </div>
                  <div
                    className={`${
                      animation && "hero__button__wrap__appear2"
                    } hero__button__wrap`}
                  >
                    <Link to="/rezervace" className="hero__button btn">
                      rezervace
                    </Link>
                  </div>
                  <div
                    className={`${
                      animation && "hero__button__wrap__appear3"
                    } hero__button__wrap`}
                  >
                    <Link to="/sluzby" className="hero__button btn">
                      služby
                    </Link>
                  </div>
                </div>
              </section>
            </div>

            <div className="section1"></div>
            <div className="intro">
              <h2 className="services__title">Služby</h2>
              <p className="intro__description">
                Vítejte na místě, kde se krása a pohoda setkávají. Ať už jste
                žena nebo muž, přijďte si dopřát péči s přírodní kosmetikou a
                zažít proměnu vaší pleti.
              </p>
            </div>

            <section className="services__products">
              {servicesData.map((service) => (
                <a
                  onClick={handleSluzbyClick}
                  className="service__product__link"
                  key={service.id}
                >
                  <div className="services__product__col__wrap">
                    <img
                      src={service.picture_path}
                      alt={service.category}
                      className="service__image"
                    />

                    <div className="services__product__col">
                      <h4 className="services__product__name">
                        {service.category}
                      </h4>
                      <div className="services__product__text__wrap">
                        <div className="services__price__wrap">
                          <p className="services__product__price">
                            {service.priceRange}
                          </p>
                        </div>
                        <p
                          className="services__product__description"
                          dangerouslySetInnerHTML={{
                            __html: service.description || "Bez popisu",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </a>
              ))}
            </section>
          </main>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Services;
