import React, { useState, useEffect } from "react";
import "./Navbar.css";
import { Link, NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import logo from "../assets/logo.webp";
import line1 from "../assets/navline1love2do.svg";
import line2 from "../assets/navline2love2do.svg";
import { gsap } from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import ScrollSmoother from "gsap/ScrollSmoother";
import ScrollTrigger from "gsap/ScrollTrigger";
import Hamburger, { Hambruger } from "hamburger-react";

gsap.registerPlugin(ScrollTrigger, ScrollSmoother, ScrollToPlugin);

const Navbar = ({ animation, scrolled, scrollValue, homepage }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (homepage) {
      const scrollTrigger = ScrollTrigger.create({
        trigger: ".reviews",
        start: "top center",
        onEnter: () => {
          gsap.to("nav a, .hamburger-react", { color: "#000", duration: 0.5 });
        },
        onLeaveBack: () => {
          gsap.to("nav a, .hamburger-react", { color: "#fff", duration: 0.5 });
        },
      });

      return () => {
        scrollTrigger.kill();
      };
    }
  }, []);

  const [isOpen, setOpen] = useState(false);

  const [windowSize, setWindowSize] = useState(null);
  const [isMobileView, setIsMobileView] = useState(false);

  const checkContainerWidth = () => {
    const newWindowSize = window.innerWidth;
    setWindowSize(newWindowSize);

    if (newWindowSize >= 1024) {
      setOpen(false);
    }
    if (newWindowSize <= 1024) {
      setIsMobileView(true);
    } else {
      setIsMobileView(false);
    }
  };

  useEffect(() => {
    checkContainerWidth();
    window.addEventListener("resize", checkContainerWidth);

    return () => {
      window.removeEventListener("resize", checkContainerWidth);
    };
  }, []);

  const closeMenu = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [isOpen]);

  const scrollToContact = () => {
    closeMenu();

    gsap.to(window, {
      scrollTo: "#kontakt",
      duration: 0.5,
      ease: "power2.inOut",
    });
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const [isSafari, setIsSafari] = useState(false);

  useEffect(() => {
    const ua = navigator.userAgent;
    const isSafariBrowser =
      ua.includes("Safari") &&
      !ua.includes("Chrome") &&
      !ua.includes("Chromium") &&
      !ua.includes("Edg") &&
      !ua.includes("OPR");

    setIsSafari(isSafariBrowser);
  }, []);

  return (
    <>
      <div
        className={`${
          scrollValue >= 180 && "navbar__background-active"
        } navbar__background`}
      ></div>
      <nav>
        {isMobileView || isSafari ? (
          <>
            <div className="nav__content-mobile">
              <Link to="/">
                <img className="nav__logo-mobile" src={logo} alt="Logo" />
              </Link>
              <Hamburger toggled={isOpen} toggle={setOpen} />
            </div>
            <div
              className={`navbar__overlay ${isOpen ? "active-overlay" : ""}`}
            >
              <div className="navbar__overlay__dimmed">
                <div className="navbar__overlay__content">
                  <Link to="/" onClick={closeMenu} href="#">
                    DOMŮ
                  </Link>
                  <Link to="/sluzby">SLUŽBY</Link>
                  <a onClick={scrollToContact} href="#">
                    KONTAKT
                  </a>
                  <Link to="/rezervace" onClick={closeMenu}>
                    REZERVACE
                  </Link>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="nav__content">
            <div
              className={`${
                scrollValue >= 110 && "nav__column__slide"
              } nav__column`}
            >
              <div
                className={`${
                  scrollValue >= 180 && "nav__ul__slide"
                } nav__ul__slide__wrap`}
              >
                <ul className={`${animation && "nav__ul__appear"}`}>
                  <li>
                    <Link to="/" onClick={scrollToTop}>
                      DOMŮ
                    </Link>
                  </li>
                  <li>
                    <Link to="/sluzby">SLUŽBY</Link>
                  </li>
                  <li>
                    <a href="#" onClick={scrollToContact}>
                      KONTAKT
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="nav__column">
              <img
                className="nav__line nav__line1"
                style={{
                  top: `${scrollValue ? -(scrollValue * 1.5) + 15 : 15}px`,
                }}
                src={line1}
                alt="Dekorativní linka"
              />
              <Link to="/">
                <img
                  className="nav__logo"
                  src={logo}
                  alt="Logo"
                  style={{
                    top: `${scrollValue ? -(scrollValue * 1.5) + 45 : 45}px`,
                  }}
                />
              </Link>
              <img
                className="nav__line nav__line2"
                style={{
                  top: `${scrollValue ? -(scrollValue * 1.5) + 15 : 15}px`,
                }}
                src={line2}
                alt="Dekorativní linka"
              />
            </div>
            <div
              className={`${
                scrollValue >= 119 && "nav__column__slide"
              } nav__column nav__column__socials`}
            >
              <div
                className={`${animation && "nav__socials__appear"} ${
                  scrollValue >= 180 && "nav__socials__slide"
                } nav__socials`}
              >
                <Link to="/rezervace">REZERVACE</Link>
                <a
                  target="_blank"
                  href="https://www.facebook.com/profile.php?id=61560177094080"
                >
                  <FaFacebook className="nav__icons" />
                </a>
                <a
                  target="_blank"
                  href="https://www.instagram.com/kosmetika.michalcikova?igsh=YjRnejRpY2FmYXc3"
                >
                  <FaInstagram className="nav__icons" />
                </a>
              </div>
            </div>
          </div>
        )}
      </nav>
    </>
  );
};

export default Navbar;
