import React from "react";

const BookingWidget = () => {
  return (
    <iframe
      src="https://noona.app/cs//mobilnikosmetika/book?iframe=true&darkModeDisabled=true&showCancelButton=true"
      style={{ border: "0", height: "90vh" }}
      width="100%"
      title="Booking Widget"
    ></iframe>
  );
};

export default BookingWidget;
