// src/data.js

// Import obrázků
import kosmetikaProMuzeImg from "../assets/data/kosmetika_pro_muze.png";
import kosmetikaProZenyImg from "../assets/data/kosmetika_pro_zeny.png";
import akneImg from "../assets/data/akne.png";
import bioPlazmaImg from "../assets/data/bio_plazma.png";
import microneedlingImg from "../assets/data/microneedling.png";
import laminaceObociImg from "../assets/data/laminace_oboci.png";
import c60Img from "../assets/data/c60.png";
import epilaceImg from "../assets/data/epilace.png";
import extraImg from "../assets/data/extra.png";

export const servicesData = [
  {
    id: 1,
    category: "Kosmetika pro muže",
    description:
      "Péče zahrnující povrchové čištění, peeling a hloubkové čištění ultrazvukovou špachtlí, zaměřená na akné, citlivou nebo unavenou pleť.",
    priceRange: "1150 - 2150 Kč",
    picture_path: kosmetikaProMuzeImg,
  },
  {
    id: 2,
    category: "Kosmetika pro ženy",
    description:
      "Komplexní péče zahrnující konzultaci, odlíčení, peeling a hloubkové čištění pleti ultrazvukovou špachtlí.",
    priceRange: "1150 - 2150 Kč",
    picture_path: kosmetikaProZenyImg,
  },
  {
    id: 3,
    category: "Akné",
    description:
      "Ošetření aknózní pleti s důkladným čištěním a hloubkovou hydratací pro zjemnění struktury pokožky.",
    priceRange: "1150 - 1450 Kč",
    picture_path: akneImg,
  },
  {
    id: 4,
    category: "Bio-Plazma",
    description:
      "Ošetření Bioplasma s izraelskou kosmetikou Gigi, které využívá kmenové buňky z mořských řas pro intenzivní hydrataci a boj s projevy stárnutí.",
    priceRange: "1390 - 1850 Kč",
    picture_path: bioPlazmaImg,
  },
  {
    id: 5,
    category: "Microneedling (mikrojehličkování)",
    description:
      "Mikrojehličkování stimuluje tvorbu kolagenu, což podporuje přirozenou regeneraci pleti a redukci jemných vrásek.",
    priceRange: "2190 - 2490 Kč",
    picture_path: microneedlingImg,
  },
  {
    id: 6,
    category: "Laminace obočí",
    description:
      "Laminace obočí srovnává asymetrii a dodává obočí plný, nadýchaný vzhled.",
    priceRange: "990 Kč",
    picture_path: laminaceObociImg,
  },
  {
    id: 7,
    category: "C60",
    description:
      "Ošetření s molekulou C60, která je silným antioxidantem, zlepšuje hydrataci pleti a působí protizánětlivě.",
    priceRange: "2490 Kč",
    picture_path: c60Img,
  },
  {
    id: 8,
    category: "Epilace bez další služby",
    description:
      "Dlouhodobé odstranění chloupků, zajišťující hladkou pokožku bez nutnosti každodenního holení.",
    priceRange: "490 - 990 Kč",
    picture_path: epilaceImg,
  },
  {
    id: 9,
    category: "Extra služby pro přírodní krásu",
    description:
      "Doplňkové procedury pro zvýraznění přirozené krásy a svěžesti vaší pleti.",
    priceRange: "70 - 1390 Kč",
    picture_path: extraImg,
  },
];
